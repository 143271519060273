import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { UserService } from '../_services/user.service';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { DialogMensajeTurnoComponent } from '../dialog-mensaje-turno/dialog-mensaje-turno.component';
import { TurnosService } from '../_services/turnos.service';
import { error } from 'console';

@Component({
  selector: 'app-dialog-confirmar-turno',
  templateUrl: './dialog-confirmar-turno.component.html',
  styleUrls: ['./dialog-confirmar-turno.component.css']
})

export class DialogConfirmarTurnoComponent implements OnInit {

  userData;
  loading = true;
  submitted = false;
  mensaje;
  loadingSubmit: boolean = false;
  showPass = [false,false,false];
  mutuales;
  mutualElegida;
  bloqueElegido;
  ocultarMsjeMutualProf: boolean = true;
  msjeMutualProf: string;
  mensajeTurnoError: string;
  tieneWebCheckIn: boolean;
  nro_dia: number;
  nombre_dia: string;
  nombre_mes: string;
  nro_ano: number;

  constructor(
    public userService: UserService,
    public dialogRef: MatDialogRef<DialogConfirmarTurnoComponent>,
    public dialog: MatDialog,
    public turnosService:TurnosService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.bloqueElegido = this.data.bloqueElegido;
    this.calcularDia();
    this.suscribirEvento();
    //this.tomarTurno()
    this.obtenerCoberturasPaciente();
  }

  async tomarTurno() {
    await this.turnosService.tomarTurno(this.bloqueElegido).toPromise().then(rpTomarTurno => {
      // console.log(rpTomarTurno);
    }, (error) => {
      console.error(error);
    });
  }

  async obtenerCoberturasPaciente() {
    await this.userService.obtenerCoberturasPaciente().toPromise().then(async data => {
      // console.log(data)
      if (data) {
        this.mutuales = data;
        this.mutualElegida = this.mutuales[0];
        await this.cambioMutual();
      }
    });
  }

  async cambioMutual() {
    this.loading = true;
    await this.verificarMutualProf(this.bloqueElegido, this.mutualElegida);
  }

  async verificarMutualProf(bloqueTurno, mutualElegida) {
    if (mutualElegida) {
      await this.turnosService.verifProfConvenio(bloqueTurno.matricula, mutualElegida.financiador).toPromise().then(async respProfConve => {
        // console.log(respProfConve)
        if (!respProfConve.ok) {
          this.msjeMutualProf = respProfConve.mensaje;
          this.ocultarMsjeMutualProf = respProfConve.ok;
        } else {
          await this.turnosService.verificarMutualProf(bloqueTurno.matricula, mutualElegida.mutual).toPromise().then(respProfMutual => {
            // console.log(respProfMutual)
            this.ocultarMsjeMutualProf = respProfMutual.ok;
            if (!this.ocultarMsjeMutualProf) {
              this.msjeMutualProf = respProfMutual.mensaje;
            }
          }, (error) => {
            console.log(error);
          });
        }
      }, (error) => {
        console.log(error);
      });
    }
    this.loading = false;
  }

  liberarTurno(bloqueTurno) {
    this.turnosService.liberarTurno(bloqueTurno).toPromise().then(rpLiberarTurno => {
      // console.log(rpLiberarTurno);
    }, error => {
      console.error(error)
    });

    this.bloqueElegido = null;
    sessionStorage.setItem('bloqueElegido', null);
  }

  confirmarTurno(bloqueTurno) {
    if (!this.ocultarMsjeMutualProf) {
      this.mutualElegida = { mutual: 14, nroCarnet: '-' };
    }
    // console.log(bloqueTurno);
    // console.log(this.mutualElegida);
    
    
    this.turnosService
      .confirmarTurno(bloqueTurno, this.mutualElegida)
      .subscribe(
        (data) => {
          if (data.ok) {
            //el tieneWebCheckin es para el dialog del mensaje de confirmacion
            if (data.webCheckIn == 'S') {
              this.tieneWebCheckIn = true;
            } else {
              this.tieneWebCheckIn = false;
            }
            this.abrirDialogMensajeTurno();
            //document.getElementById('openModalConfirmarTurno').click();
            sessionStorage.setItem('bloqueElegido', null);
            this.bloqueElegido = null;
          } else {
            this.mensajeTurnoError = data.mensaje;
            data.titulo = 'No se pudo reservar el turno'
            this.abrirDialogMensaje(data);
            this.liberarTurno(this.bloqueElegido);
            //document.getElementById('openModalConfirmarTurnoError').click();
          }
          
        },
        (error) => {
          console.log(error);
          this.loadingSubmit = false;
        }
      );
  }

  async obtenerDatosUsuario() {
    await this.userService.getUserData().toPromise().then((response) => {
     // console.log(response);
      this.userData = response;
      this.loading = false;
    }, (error) => {
      console.log(error);
    });
  }


  onSubmit() {
    this.submitted = true;
    this.loadingSubmit = true;    
    this.confirmarTurno(this.bloqueElegido);    
    this.submitted = false;
  }

  abrirDialogMensaje(resp) {
    this.loadingSubmit = false;
    this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: resp,
      panelClass: 'dialog-sin-overflow'
    }).afterClosed()
    .subscribe(
      (data) => {
        this.dialog.closeAll();
        
      },
      (error) => {
        console.log(error);
      }
    );
  }

  abrirDialogMensajeTurno() {
    this.loadingSubmit = false;
    this.dialog.open(DialogMensajeTurnoComponent, {
      width: '500px',
      disableClose: true,
      data: {tieneWebCheckIn:this.tieneWebCheckIn},
      panelClass: 'dialog-sin-overflow'
    }).afterClosed().subscribe((data) => {
      this.cerrarDialogo(true);          
    }, (error) => {
      console.log(error);
    });
  }

  cerrarDialogo(confirmado) {
    if (!confirmado){
      this.liberarTurno(this.bloqueElegido);
    }
    this.dialogRef.close(confirmado);
  }

  calcularDia() {
    let fecha = new String(this.data.bloqueElegido.str_fecha);
    let spliteado = fecha.split('/');  
    let date = new Date(parseInt(spliteado[2]), parseInt(spliteado[1]) - 1, parseInt(spliteado[0]));
    this.nro_dia = date.getDate();
    switch (date.getMonth() + 1 ) {  //Date numera los meses de 0 a 11, no de 1 a 12
      case 1:
        this.nombre_mes='enero';
        break;
      case 2:
        this.nombre_mes='febrero';
        break;
      case 3:
        this.nombre_mes='marzo';
        break;
      case 4:
        this.nombre_mes='abril';
        break;
      case 5:
        this.nombre_mes='mayo';
        break;
      case 6:
        this.nombre_mes='junio';
        break;
      case 7:
        this.nombre_mes='julio';
        break;
      case 8:
        this.nombre_mes='agosto';
        break;
      case 9:
        this.nombre_mes='septiembre';
        break;
      case 10:
        this.nombre_mes='octubre';
        break;
      case 11:
        this.nombre_mes='noviembre';
        break;
      case 12:
        this.nombre_mes='diciembre';
        break;                                                                                                
    }
 
    this.nro_ano = date.getFullYear();
  }
  
  suscribirEvento(){
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
          this.cerrarDialogo(false);
      }
  });

  }
}

