import { Component, OnInit } from '@angular/core';
import { MercadoPagoService } from '../_services/mercado-pago.service';
import { Router } from '@angular/router';


export interface Cobrar {
  nroBoleta: number;
  nroTransaccion: number;
  importeTotal: number;
}

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.css'],
})
export class PagoComponent implements OnInit {

  tokenMercadoPago: string;
  resultadoPago: boolean = false;
  cargando: boolean = true;
  msjeResultado: string;
  urlActual;
  urlArray;
  idPagoArray;
  idPago;

  constructor(
    private mercadoPagoService: MercadoPagoService,
    private router: Router
  ) { }

  ngOnInit() {
    this.obtenerUrlActual();
  }

  async obtenerUrlActual() {
    this.urlActual = window.location.href;
    this.urlArray = this.urlActual.split('?');
    this.urlActual = this.urlArray[1];
    this.urlArray = this.urlActual.split('&');
    this.urlActual = this.urlArray[0];
    this.idPagoArray = this.urlActual.split('=');
    this.idPago = this.idPagoArray[1];

    await this.getTokenMercadoPago();
  }

  async getTokenMercadoPago() {
    await this.mercadoPagoService.getTokenMercadoPago().toPromise().then(async rpGetTokenMp => {
      // console.log(rpGetTokenMp);
      if (rpGetTokenMp.ok) {
        this.tokenMercadoPago = rpGetTokenMp.mensaje;
        await this.mercadoPagoService.getDetallePago(this.idPago, this.tokenMercadoPago).toPromise().then((responsePago) => {
          // console.log(responsePago);
          if (responsePago.status == 'approved') {
            this.msjeResultado = 'El pago se realizó exitosamente.';
            this.resultadoPago = true;
            this.cargando = false;
          }
        }, (error) => {
          console.log(error);
          this.msjeResultado = 'No se puedo procesar el pago (COD: 1010)';
          this.resultadoPago = false;
          this.cargando = false;
        });
      } else {
        this.msjeResultado = 'No se puedo procesar el pago (COD: 1011)';
        this.resultadoPago = false;
        this.cargando = false;
      }
    }, (error) => {
      console.log(error);
      this.msjeResultado = 'No se puedo procesar el pago (COD: 1012)';
      this.resultadoPago = false;
      this.cargando = false;
    });
  }

  finalizar() {
    this.router.navigate(['turnos-reservados']);
  }
}
