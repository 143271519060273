import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AppComponent } from "../app.component";

@Injectable({
  providedIn: "root",
})
export class MercadoPagoService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = AppComponent.testUrl;
  }

  obtenerLinkPago(mercadoPago) {
    let body = new FormData();

    body.append("importeTotal", mercadoPago.importeTotal);
    body.append("nroBoleta", mercadoPago.nroBoleta);

    return this.http.post<any>(this.baseUrl + "/MP/procesar-pago", body);
  }

  getDetallePago(idPago, tokenMercadoPago) {
    return this.http.get<any>(
      "https://api.mercadopago.com/v1/payments/" +
        idPago +
        "?access_token=" +
        tokenMercadoPago
    );
  }

  getDetalleOrden(idOrden, tokenMercadoPago) {
    return this.http.get<any>(
      "https://api.mercadopago.com//merchant_orders/" +
        idOrden +
        "?access_token=" +
        tokenMercadoPago
    );
  }

  getTokenMercadoPago() {
    return this.http.get<any>(this.baseUrl + "/token-mercadopago");
  }

  cobrarComprobanteCaja(cobrar) {
    let body = new FormData();

    body.append("nroBoleta", cobrar.nroBoleta);
    body.append("nroTransaccion", cobrar.nroTransaccion);
    body.append("importeTotal", cobrar.importeTotal);

    return this.http.post<any>(this.baseUrl + "/MP/cobrarComprobanteCaja", body);
  }

  obtenerComprobanteCaja(cobrar) {
    let body = new FormData();

    body.append("nroBoleta", cobrar.nroBoleta);

    return this.http.post<any>(this.baseUrl + "/MP/obtenerComprobanteCaja", body);
  }
}
